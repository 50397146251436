.windows-simulation {
    background-image: url('../assets/windows/windowsBackground.jpeg');
    background-size: cover;
}

.desktop {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.folder {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.folder img {
    width: 50px;
    height: 50px;
}

.folder p {
    margin-top: 10px;
}
